var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"categoryForm"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveChanges.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"category_name"}},[_vm._v("Category Name:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.category.name),expression:"category.name"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{
          'form-control': true,
          'is-invalid': _vm.errors.has('category_name')
        },attrs:{"type":"text","name":"category_name","id":"category_name","placeholder":""},domProps:{"value":(_vm.category.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.category, "name", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please provide a valid category name. ")])]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"category_slug"}},[_vm._v("Slug:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.category.slug),expression:"category.slug"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{
          'form-control': true,
          'is-invalid': _vm.errors.has('category_slug'),
        },attrs:{"type":"text","name":"category_slug","id":"category_slug","placeholder":""},domProps:{"value":(_vm.category.slug)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.category, "slug", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please provide a valid category slug. ")])]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"meta_title"}},[_vm._v("Meta Title (SEO):")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.category.meta_title),expression:"category.meta_title"}],staticClass:"form-control",attrs:{"type":"text","name":"meta_title","id":"meta_title","placeholder":""},domProps:{"value":(_vm.category.meta_title)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.category, "meta_title", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please provide a valid meta title. ")])]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"meta_description"}},[_vm._v("Meta Description (SEO):")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.category.meta_description),expression:"category.meta_description"}],staticClass:"form-control",attrs:{"name":"meta_description","id":"meta_description","placeholder":""},domProps:{"value":(_vm.category.meta_description)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.category, "meta_description", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please provide a valid meta description. ")])]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"sort_date"}},[_vm._v("Sort Date:")]),_c('datepicker',{attrs:{"placeholder":"Sort Date","name":"uniquename","input-class":"form-control"},model:{value:(_vm.category.sort_date),callback:function ($$v) {_vm.$set(_vm.category, "sort_date", $$v)},expression:"category.sort_date"}})],1),_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-primary"},[_vm._v("Save")])])
}]

export { render, staticRenderFns }