<template>
  <div class="categoryForm">
    <form v-on:submit.prevent="saveChanges">
      <div class="form-group">
        <label for="category_name">Category Name:</label>
        <input
          type="text"
          v-model="category.name"
          :class="{
            'form-control': true,
            'is-invalid': errors.has('category_name')
          }"
          name="category_name"
          id="category_name"
          placeholder=""
          v-validate="'required'"
        />
        <div class="invalid-feedback">
          Please provide a valid category name.
        </div>
      </div>

      <div class="form-group">
        <label for="category_slug">Slug:</label>
        <input
          type="text"
          v-model="category.slug"
          :class="{
            'form-control': true,
            'is-invalid': errors.has('category_slug'),
          }"
          name="category_slug"
          id="category_slug"
          placeholder=""
          v-validate="'required'"
        />
        <div class="invalid-feedback">
          Please provide a valid category slug.
        </div>
      </div>

      <div class="form-group">
        <label for="meta_title">Meta Title (SEO):</label>
        <input
          type="text"
          v-model="category.meta_title"
          class="form-control"
          name="meta_title"
          id="meta_title"
          placeholder=""
        />
        <div class="invalid-feedback">
          Please provide a valid meta title.
        </div>
      </div>

      <div class="form-group">
        <label for="meta_description">Meta Description (SEO):</label>
        <textarea
          v-model="category.meta_description"
          class="form-control"
          name="meta_description"
          id="meta_description"
          placeholder=""
        ></textarea>
        <div class="invalid-feedback">
          Please provide a valid meta description.
        </div>
      </div>

      <div class="form-group">
        <label for="sort_date">Sort Date:</label>
        <datepicker
          placeholder="Sort Date"
          v-model="category.sort_date"
          name="uniquename"
          input-class="form-control"
        ></datepicker>
      </div>

      <div class="form-group">
        <button class="btn btn-primary">Save</button>
      </div>
    </form>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    datepicker: Datepicker
  },

  methods: {
    saveChanges: function() {
      let token = this.$cookies.get("poolfit_admin_session");

      if (token !== null) {
        let options = {
          headers: { "X-Access-Token": token }
        };

        if (!isNaN(this.$route.params.id)) {
          this.$http
            .patch(
              process.env.VUE_APP_ROOT_API +
                "/marketplace/categories/" +
                this.$route.params.id,
              this.category,
              options
            )
            .then(response => {
              if (response.status === 200) {
                alert("Record updated successfully");
              }
            });
        } else if (this.$route.params.id == "add") {
          this.$http
            .post(
              process.env.VUE_APP_ROOT_API + "/marketplace/categories",
              this.category,
              options
            )
            .then(response => {
              if (response.status === 200) {
                alert("Record created successfully");
              }
            });
        }
      }
    }
  },

  data: function() {
    return {
      category: {}
    };
  },

  created: function() {
    if (!isNaN(this.$route.params.id)) {
      this.$http
        .get(
          process.env.VUE_APP_ROOT_API +
            "/marketplace/categories/" +
            this.$route.params.id
        )
        .then(response => {
          this.category = response.data;
        });
    } else if (this.$route.params.id !== "add") {
      alert("Invalid parameter.");
    }
  }
};
</script>
<style></style>
